<template>
  <topbar
    :titulo="$t('ACADEMICO.IMPRIMIR_RELATORIO_VALORES_PARA_CONTRATOS')"
    :subtitulo="
      $t('ACADEMICO.IMPRIMIR_RELATORIO_VALORES_PARA_CONTRATOS_DESCRICAO')
    "
    :breadcrumbs="breadcrumbs"
  />
</template>
<script>
import Topbar from '@/layouts/full-layout/horizontal-sidebar/Topbar.vue';

export default {
  components: { Topbar },
  data() {
    return {
      breadcrumbs: [
        { titulo: this.$t('ACADEMICO.ACADEMICO') },
        { titulo: this.$t('ACADEMICO.RELATORIOS') },
        {
          titulo: this.$t(
            'ACADEMICO.IMPRIMIR_RELATORIO_VALORES_PARA_CONTRATOS'
          ),
        },
      ],
    };
  },
  methods: {},
};
</script>
