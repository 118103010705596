<template>
  <div>
    <titulo-relatorio-valores-contratos />
    <b-row>
      <b-col cols="12" md="3" class="mt-3">
        <input-select-multiple
          ref="tipoAnalise"
          v-model="selecionados.tipoAnalise"
          :label="$t('ACADEMICO.TIPO_ANALISE')"
          :options="opcoes.tiposAnalises"
          required
        />
      </b-col>
      <b-col cols="12" md="3" class="mt-3">
        <input-select-multiple
          ref="anoLetivoId"
          v-model="selecionados.anoLetivoId"
          :label="$t('ACADEMICO.ANO_LETIVO')"
          :options="opcoes.anosLetivos"
          required
        />
      </b-col>

      <b-col cols="12" md="3" class="mt-3">
        <input-select-multiple
          ref="tipoContrado"
          v-model="selecionados.tipoContrato"
          :label="$t('ACADEMICO.TIPO_CONTRATO')"
          :options="opcoes.tipoContratos"
          required
        />
      </b-col>

      <b-col cols="12" md="3" class="mt-3">
        <input-select-multiple
          ref="statusContrato"
          v-model="selecionados.status"
          :label="$t('ACADEMICO.STATUS_CONTRATO')"
          :options="opcoes.statusContratos"
          required
        />
      </b-col>

      <b-col cols="12" md="3" class="mt-3">
        <input-date-only-btn
          ref="peridioInicio"
          v-model="selecionados.periodoInicial"
          :label="'Período inicio'"
          required
        />
      </b-col>

      <b-col cols="12" md="3" class="mt-3">
        <input-date-only-btn
          ref="periodoFim"
          v-model="selecionados.periodoFim"
          :label="'Período fim'"
          required
        />
      </b-col>

      <b-col
        cols="12"
        md="3"
        class="mt-3"
        v-if="selecionados.tipoAnalise && selecionados.tipoAnalise.id === 3"
      >
        <input-select-multiple
          ref="descontos"
          v-model="selecionados.descontoId"
          :label="$t('GERAL.DESCONTO')"
          :options="opcoes.descontos"
        />
      </b-col>

      <b-col
        cols="12"
        md="3"
        class="mt-3"
        v-if="selecionados.tipoAnalise && selecionados.tipoAnalise.id === 2"
      >
        <input-select-multiple
          ref="historicos"
          v-model="selecionados.historicoId"
          :label="$t('GERAL.HISTORICO')"
          :options="opcoes.historicos"
        />
      </b-col>

      <b-col cols="12" md="auto" class="mt-3">
        <b-button
          class="botao-acao-filtro w-100"
          variant="info"
          @click="realizarImpressaoRelatorioValoresContratos"
        >
          {{ $t('GERAL.GERAR_RELATORIO') }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import pdf from '@/common/utils/pdf';
import helpers from '@/common/utils/helpers';
import mensagem from '@/common/utils/mensagem';

// Services:
import AnoLetivoService from '@/common/services/anoLetivo/ano-letivo.service';
import ContratoService from '@/common/services/contratos/contratos.service';
import DescontosService from '@/common/services/descontos/descontos.service';
import EnumeradoresService from '@/common/services/enumeradores/enumeradores.service';
import HistoricoLancamentoService from '@/common/services/historico-lancamento/historico-lancamento.service';

// Components:
import TituloRelatorioValoresContratos from '@/views/academico/relatorios/relatorio-valores-contratos/TituloRelatorioValoresContratos';
import { InputSelectMultiple, InputDateOnlyBtn } from '@/components/inputs';

export default {
  components: {
    TituloRelatorioValoresContratos,
    InputSelectMultiple,
    InputDateOnlyBtn,
  },

  template: {},
  data() {
    return {
      selecionados: {},
      opcoes: {
        anosLetivos: [],
        tipoContratos: [],
        statusContratos: [],
        tiposAnalises: [],
        descontos: [],
        historicos: [],
      },
    };
  },
  mounted() {
    this.getAnosLetivos();
    this.getTipoContratos();
    this.getStatusContratos();
    this.getDescontos();
    this.getHistoricoLancamento();
    this.getTipoAnalise();
  },
  methods: {
    validarFormulario() {
      if (this.selecionados.periodoFim < this.selecionados.periodoInicial) {
        mensagem.erro(
          this.$t('GERAL.ERRO'),
          this.$t('ACADEMICO.DATAS_INVALIDAS')
        );
        return;
      }
      return helpers.validarFormulario(this.$refs);
    },
    realizarImpressaoRelatorioValoresContratos() {
      if (!this.validarFormulario()) return;
      this.$store.dispatch(START_LOADING);

      const {
        anoLetivoId,
        tipoContrato,
        status,
        descontoId,
        historicoId,
        tipoAnalise,
        periodoInicial,
        periodoFim,
      } = this.selecionados;

      const objetoParaFiltro = {
        anoLetivoId: anoLetivoId.id,
        tipoContrato: tipoContrato.id,
        status: status.id,
        tipoAnalise: tipoAnalise.id,
        descontoId: descontoId?.value,
        historicoId: historicoId?.value,
        periodoInicial,
        periodoFim,
      };

      ContratoService.imprimirRelatorioValoresContratos(objetoParaFiltro)
        .then(({ data }) => {
          if (data.documento) {
            let bytes = pdf.base64ToArrayBuffer(data.documento);

            let blob = new Blob([bytes], { type: 'application/pdf' });

            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = data.nomeArquivo;
            link.click();
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getTipoAnalise() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('tipo-analise')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.tiposAnalises = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getStatusContratos() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('status-contrato')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));

          this.opcoes.statusContratos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getTipoContratos() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('tipo-contratos')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.tipoContratos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getAnosLetivos() {
      this.$store.dispatch(START_LOADING);
      AnoLetivoService.listar()
        .then(({ data }) => {
          data = data.map((row) => {
            return {
              ...row,
              text: row.ano,
              value: row.id,
            };
          });

          this.opcoes.anosLetivos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getHistoricoLancamento() {
      this.$store.dispatch(START_LOADING);
      HistoricoLancamentoService.obterTodosHistoricosLancamentosSemPaginacao()
        .then(({ data }) => {
          this.opcoes.historicos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getDescontos() {
      this.$store.dispatch(START_LOADING);
      DescontosService.obterTodos()
        .then(({ data }) => {
          data = data.map((row) => {
            return {
              ...row,
              text: row.descricao,
              value: row.id,
            };
          });

          this.opcoes.descontos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>

<style>
.ajuste-fino-btn {
  margin-top: 29px;
}
</style>
